<template>
  <ul
    class="email-media-list"
  >
    <b-media
      v-for="(row) in listviewData"
      :key="row.id"
      tag="li"
      no-body
      @click="setProposalDetail(true, row.id_proposta)"
    >
      <b-media-body>
        <div class="mail-details">
          <b-container
            fluid
          >
            <b-row>
              <b-col md="12">
                <h5
                  v-if="row.pid"
                  class=""
                >
                  {{ row.pid }} - {{ row.code_proposta }}

                  <b-badge
                    v-if="Number(row.reserva_ja) === 1"
                    variant="light-primary"
                  >
                    {{ $t('Zome Now') }}
                  </b-badge>
                </h5>

              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="row.estado_proposta"
                md="2"
                class="mt-1"
              >
                <span>
                  {{ row.estado_proposta }}
                </span>
                <div
                  v-if="row.estado_proposta"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Estado') }}
                  </p>
                </div>
              </b-col>
              <b-col
                v-if="row.data_proposta"
                md="2"
                class="mt-1"
              >
                <span>
                  {{ row.data_proposta }}
                </span>
                <div
                  v-if="row.data_proposta"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Data de início') }}
                  </p>
                </div>
              </b-col>
              <b-col
                v-if="row.ultimaNegociacao"
                md="2"
                class="mt-1"
              >
                <span>
                  {{ row.ultimaNegociacao }}
                </span>
                <div
                  v-if="row.ultimaNegociacao"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Última negociação') }}
                  </p>
                </div>
              </b-col>
              <b-col
                v-if="row.criado_por"
                md="3"
                class="mt-1"
              >
                <span
                  v-if="row.criado_por"
                >
                  <feather-icon
                    icon="UserIcon"
                  />
                  {{ row.criado_por }}
                </span>
                <br>
                <span
                  v-if="row.contacto_criado"
                >
                  <feather-icon
                    icon="PhoneCallIcon"
                  />
                  {{ row.contacto_criado }}
                </span>
                <div
                  v-if="row.criado_por"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Criado por') }}
                  </p>
                </div>
              </b-col>
              <b-col
                v-if="row.valor_reserva"
                md="2"
                class="mt-1"
              >
                <span>
                  {{ row.valor_reserva }}
                </span>
                <div
                  v-if="row.valor_reserva"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Valor Referido') }}
                  </p>
                </div>
              </b-col>
              <b-col
                v-if="row.data_validade"
                md="2"
                class="mt-1"
              >
                <span>
                  {{ row.data_validade }}
                </span>
                <div
                  v-if="row.data_validade"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Expira a') }}
                  </p>
                </div>
              </b-col>
              <b-col
                v-if="tabSelectFilter === 'sent' && [4, 7].includes(Number(currentUser.id_role))"
                md="2"
                class="mt-1"
              >
                <span
                  v-if="row.name_lead"
                >
                  <feather-icon
                    icon="UserIcon"
                  />
                  {{ row.name_lead }}
                </span>
                <div
                  v-if="row.name_lead"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $tc('Lead', 1) }}
                  </p>
                </div>
              </b-col>
              <b-col
                v-if="[4, 7].includes(Number(currentUser.id_role)) && ((tabSelectFilter !== 'sent' && tabSelectFilter !== 'reserve') || (typeof currentUser.extras !== 'undefined' && typeof currentUser.extras.idLeader !== 'undefined' && currentUser.extras.idLeader !== ''))"
                md="2"
                class="mt-1"
              >
                <span
                  v-if="row.nome_consultor"
                >
                  <feather-icon
                    icon="UserIcon"
                  />
                  {{ row.nome_consultor }}
                </span>
                <br>
                <span
                  v-if="row.contacto_consultor"
                >
                  <feather-icon
                    icon="PhoneCallIcon"
                  />
                  {{ row.contacto_consultor }}
                </span>
                <div
                  v-if="row.contacto_consultor"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Consultor') }}
                  </p>
                </div>
              </b-col>
              <b-col
                v-if="([5, 6, 9, 10, 13, 11, 12].includes(Number(currentUser.id_role)) && tabSelectFilter !== 'reserve') || ([4, 7].includes(Number(currentUser.id_role)) && tabSelectFilter === 'sent')"
                md="2"
                class="mt-1"
              >
                <span
                  v-if="row.nome_angariador"
                >
                  <feather-icon
                    icon="UserIcon"
                  />
                  {{ row.nome_angariador }}
                </span>
                <br>
                <span
                  v-if="row.contacto_angariador"
                >
                  <feather-icon
                    icon="PhoneCallIcon"
                  />
                  {{ row.contacto_angariador }}
                </span>
                <div
                  v-if="row.contacto_angariador"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Angariador') }}
                  </p>
                </div>
              </b-col>
              <b-col
                v-if="[5, 6, 9, 10, 13, 11, 12].includes(Number(currentUser.id_role))"
                md="2"
                class="mt-1"
              >
                <span
                  v-if="row.nome_consultor"
                >
                  <feather-icon
                    icon="UserIcon"
                  />
                  {{ row.nome_consultor }}
                </span>
                <br>
                <span
                  v-if="row.contacto_consultor"
                >
                  <feather-icon
                    icon="PhoneCallIcon"
                  />
                  {{ row.contacto_consultor }}
                </span>
                <div
                  v-if="row.contacto_consultor"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Proposto por') }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>

      </b-media-body>
    </b-media>
  </ul>
</template>

<script>
import {
  BMedia, BMediaBody, VBTooltip, BRow, BCol, BContainer, BBadge,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BMedia,
    BMediaBody,
    BRow,
    BCol,
    BContainer,
    BBadge,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
    setProposalDetail: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('crm_proposals', ['listviewData', 'tabSelectFilter']),
    ...mapGetters('auth', ['currentUser']),
  },
}
</script>
